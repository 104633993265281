import { useCallback } from 'react'

import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { isAfter, isBefore, isSameDay, startOfDay } from 'date-fns'
import { Controller, RegisterOptions, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { PlanCard } from 'app/lib/components/plans/PlanCard'
import { fromISOdate, isValidDate, today, toISOdate, yesterday } from 'app/lib/utils/date'
import { EligibilityDirective } from 'app/models/EligibilityRecord'
import {
  getCurrentAndOtherPlans,
  getDefaultAndOtherPlans,
  isArchived,
} from 'app/models/Organization'
import { EligibilityRecord, OrganizationRoute } from 'app/models/scribe.models'
import {
  useLegacyGetOrganizationPlansQuery,
  useSubmitEligibilityDirectiveMutation,
} from 'app/redux/scribeApi'

import { ActionType } from './Profile'

type AddEditMemberDialogProps = Readonly<{
  actionType: ActionType
  eligibilityRecord: EligibilityRecord
  onClose: () => void
}>

export type updateRecordStatusParams = Readonly<{
  activationDate: Date | null
  deactivationDate: Date | null
}>

export function UpdateStatusDialog(props: AddEditMemberDialogProps) {
  const { t } = useTranslation()
  const { actionType, onClose, eligibilityRecord } = props

  const {
    attributes: { uniqueIdentifier, firstName, lastName },
    eligibleIntervals,
  } = eligibilityRecord

  const lastInterval = eligibleIntervals[eligibleIntervals.length - 1]
  const defaultActivationDate = lastInterval?.startDate && fromISOdate(lastInterval.startDate)
  const isFutureActivation = defaultActivationDate && isAfter(defaultActivationDate, today())

  const { organizationId } = useParams() as OrganizationRoute

  const { data: plans } = useLegacyGetOrganizationPlansQuery(organizationId)
  const [submitDirective] = useSubmitEligibilityDirectiveMutation({
    fixedCacheKey: 'eligibilityRecordUpdate',
  })

  const [currentPlan] = getCurrentAndOtherPlans(plans, eligibilityRecord)
  const [defaultPlan] = getDefaultAndOtherPlans(plans)

  const isReactivation = actionType === ActionType.REACTIVATE

  const changeCurrentPlan = isReactivation && currentPlan && isArchived(currentPlan) && defaultPlan

  const defaultValues = isReactivation
    ? { activationDate: today(), deactivationDate: null }
    : {
        activationDate: null,
        deactivationDate: isFutureActivation ? defaultActivationDate : today(),
      }

  const shouldDisableDate = useCallback(
    (date: Date) => {
      const thisDay = startOfDay(today())
      const previousDay = startOfDay(yesterday())
      if (isReactivation) {
        return isBefore(date, thisDay)
      }
      if (isFutureActivation) {
        return (
          isBefore(date, defaultActivationDate) &&
          !isSameDay(date, defaultActivationDate) &&
          !isSameDay(date, previousDay)
        )
      }
      return isBefore(date, previousDay)
    },
    [defaultActivationDate],
  )

  const { control, handleSubmit, reset } = useForm<updateRecordStatusParams>({ defaultValues })

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [reset, onClose])

  const updateRecordStatus = useCallback(
    ({ activationDate, deactivationDate }: updateRecordStatusParams) => {
      const body: EligibilityDirective = {
        uniqueIdentifier,
        activationDate: activationDate ? toISOdate(activationDate) : undefined,
        deactivationDate: deactivationDate ? toISOdate(deactivationDate) : '#',
      }
      if (changeCurrentPlan) {
        body.plan = defaultPlan?.attributes.label
      }
      return submitDirective({ organizationId, body }).unwrap().then(handleClose)
    },
    [
      organizationId,
      uniqueIdentifier,
      changeCurrentPlan,
      defaultPlan,
      submitDirective,
      handleClose,
    ],
  )

  const requiredRule: RegisterOptions = {
    required: t('global.isRequired', '', {
      label: t(`eligibilityRecordPage.dialog.${actionType}.date.label`),
    }) as string,
  }

  const validationRule: RegisterOptions = {
    validate: (val: Date) => {
      if (val && !isValidDate(val)) {
        return t('global.invalidDate') as string
      }

      if (shouldDisableDate(val)) {
        return t('global.notAllowedDate') as string
      }

      return true
    },
  }

  const hasName = firstName && lastName

  return (
    <Dialog fullWidth maxWidth="md" open onClose={onClose} data-testid="deactivation-dialog">
      <form onSubmit={handleSubmit(updateRecordStatus)} noValidate>
        <DialogContent sx={{ padding: 4 }}>
          <Stack spacing={4}>
            <Typography variant="h3">
              {hasName
                ? t(`eligibilityRecordPage.dialog.${actionType}.title`, { firstName, lastName })
                : t(`eligibilityRecordPage.dialog.${actionType}.titleNoName`)}
            </Typography>
            <Stack spacing={3}>
              <Typography variant="body2">
                {hasName
                  ? t(`eligibilityRecordPage.dialog.${actionType}.areYouSure`, {
                      firstName,
                      lastName,
                    })
                  : t(`eligibilityRecordPage.dialog.${actionType}.areYouSureNoName`)}
              </Typography>
              <Controller
                name={isReactivation ? 'activationDate' : 'deactivationDate'}
                control={control}
                rules={isReactivation ? { ...requiredRule, ...validationRule } : validationRule}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label={t(`eligibilityRecordPage.dialog.${actionType}.date.label`)}
                    format="yyyy-MM-dd"
                    openTo="day"
                    views={['year', 'day']}
                    shouldDisableDate={shouldDisableDate}
                    slotProps={{
                      textField: {
                        name: 'date',
                        error: !!error,
                        helperText: error?.message,
                        required: isReactivation,
                        variant: 'outlined',
                        sx: {
                          maxWidth: '320px',
                        },
                      },
                    }}
                  />
                )}
              />
            </Stack>
            {changeCurrentPlan && (
              <Stack spacing={2}>
                <Typography variant="body2">
                  {t(`eligibilityRecordPage.dialog.reactivate.assignedDefaultPlan`)}
                </Typography>
                <PlanCard plan={defaultPlan} />
              </Stack>
            )}
            {isReactivation && (
              <Typography variant="body2">
                {t(`eligibilityRecordPage.dialog.reactivate.sendNewMembersAnInviteNotAllowed`)}
              </Typography>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ paddingX: 4, paddingBottom: 4, paddingTop: 0 }}>
          <Button variant="outlined" onClick={handleClose}>
            {t('global.dialog.cancel')}
          </Button>
          <Button variant="contained" type="submit" key="preventDoubleSubmit">
            {t(`global.dialog.${actionType}`)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
